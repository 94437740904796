
<!-- 
A view with a list of buttons to call the following httpsCallable functions found in @/services/functions.js:
- createScrapFNSnapshotsLight
- createScrapFNMaps
- addTimestampToScrapFNSnapshots
- getIslandStatsFromLatestSnapshot
- buildScrapFNSnapshotsDaily
and displays the result of the function.
-->

<template>
  <div class="d-flex flex-column sub-mt-2">
    <b-button @click="createScrapFNSnapshotsLight()">Create ScrapFN Snapshots Light</b-button>
    <b-button @click="createScrapFNMaps()">Create ScrapFN Maps Info and History</b-button>
    <b-button @click="addTimestampToScrapFNSnapshots()">Add Timestamp to ScrapFN Snapshots</b-button>
    <b-button @click="getIslandStatsFromLatestSnapshot()">Get Island Stats from Latest Snapshot</b-button>
    <b-button @click="buildScrapFNSnapshotsDaily()">Build ScrapFN Snapshots Daily</b-button>
    <div v-if="result">{{ result }}</div>
  </div>
</template>

<script>
import { callable } from '@/services/functions.js'
import { getLog } from '@/services/log'
const log = getLog('Ops')

// Admin
export let createScrapFNSnapshotsLight = callable("scrapfn-createScrapFNSnapshotsLight");
export let createScrapFNMaps = callable("scrapfn-createScrapFNMaps");
export let addTimestampToScrapFNSnapshots = callable("scrapfn-addTimestampToScrapFNSnapshots");
export let getIslandStatsFromLatestSnapshot = callable("scrapfn-getIslandStatsFromLatestSnapshot");
export let buildScrapFNSnapshotsDaily = callable("scrapfn-buildScrapFNSnapshotsDaily");

export default {
  data() {
    return {
      result: null
    }
  },
  mounted() {
    log.log('mounted')
  },
  methods: {
    async createScrapFNSnapshotsLight() {
      this.result = await createScrapFNSnapshotsLight()
    },
    async createScrapFNMaps() {
      this.result = await createScrapFNMaps()
    },
    async addTimestampToScrapFNSnapshots() {
      this.result = await addTimestampToScrapFNSnapshots()
    },
    async getIslandStatsFromLatestSnapshot() {
      this.result = await getIslandStatsFromLatestSnapshot()
    },
    async buildScrapFNSnapshotsDaily() {
      this.result = await buildScrapFNSnapshotsDaily()
    }
  }
}
</script>


